import * as u from '../utils'

u.domReady(function() {
  const elements = document.querySelectorAll('.footnote-inline')
  const parent = document.querySelector('.content')

  if (elements.length == 0 || parent == null) return;

  let wrapper = document.createElement('div')
  wrapper.classList.add( 'footnotes' )

  let list = document.createElement('ol')



  Array.from(elements).forEach(function(el, i) {
    const id = i+1
    const data = el.dataset.footnoteContent

    let link = document.createElement('a')
        link.href = '#footnote_' + id
        link.classList.add( 'footnote-inline__link', 'scrollto' )
        link.innerHTML = id

    let note = document.createElement('li')
        note.id = 'footnote_' + id
        note.classList.add( 'footnote__item' )
        note.innerHTML = data

    el.appendChild(link)
    list.appendChild(note)
  })

  wrapper.appendChild(list)
  parent.appendChild(wrapper)
})
