import * as u from './utils'
import jQuery from "jquery";
var $ = jQuery;
jQuery.fn.extend({
  makeSlideshow: function(controls, strapline, popup, link) {
    var activateSlide, currentSlide, index, nextSlide, nextSlideTimer, slide, slides, timeToNextSlide;
    slides = this.children();
    if (slides.length === 0) {
      return;
    }
    currentSlide = 0;
    timeToNextSlide = 10000;
    nextSlideTimer = null;
    activateSlide = (function(_this) {
      return function(id) {
        var el;
        el = _this.find('.active');
        el.removeClass('active').removeClass('retired').addClass('retired');
        slides[id].addClass('active').removeClass('retired');
        if (controls) {
          controls.children().removeClass('active');
          $(controls.children()[id]).addClass('active');
        }
        if (strapline) {
          strapline.html(slides[id].data('text'));
        }
        if (link) {
          link.attr('href', slides[id].data('url'));
        }
        if (popup) {
          popup.children().remove();
          popup.append(slides[id].find("[data=\"popup\"]").clone());
        }
        return currentSlide = id;
      };
    })(this);
    nextSlide = function() {
      if (!$('.intro').hasClass('show_popup')) {
        currentSlide = (currentSlide + 1) % slides.length;
        activateSlide(currentSlide);
      }
      return nextSlideTimer = setTimeout(nextSlide, timeToNextSlide);
    };
    slides = (function() {
      var j, len, results;
      results = [];
      for (index = j = 0, len = slides.length; j < len; index = ++j) {
        slide = slides[index];
        results.push((function(s, i) {
          var c;
          c = $("<li><a href=\"#\">" + (index + 1) + "</a></li>");
          c.find('a').on('click', function(e) {
            e.preventDefault();
            activateSlide(i);
            clearTimeout(nextSlideTimer);
            return nextSlideTimer = setTimeout(nextSlide, timeToNextSlide);
          });
          if (controls) {
            controls.append(c);
          }
          return $(s);
        })(slide, index));
      }
      return results;
    })();
    activateSlide(currentSlide);
    nextSlideTimer = setTimeout(nextSlide, timeToNextSlide);
    if (popup) {
      return popup.find('a.close').on('click', function(event) {
        clearTimeout(nextSlideTimer);
        return nextSlideTimer = setTimeout(nextSlide, timeToNextSlide);
      });
    }
  }
});
u.domReady(function() {
  $("#together-we-rock .single-article__content-text .pdf__link").each(function(index, link) {
    var $link = $(link)
    var slugifiedTitle = $(link).html().toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    var id = 'pdf-' + slugifiedTitle + '-' + index;

    if ( !$( "#" + id ).length ) {
      $( "<object id=" + id + " data=" + $link.attr('href') + "></object>" ).insertAfter( $(link) );
    }
  })
})

u.domReady(function() {
  var $main_navigation;
  $main_navigation = $('#main_navigation');
  // enquire.register('screen and (min-width: 60em)', {
  //   match: function() {
  //     $main_navigation.show();
  //   }
  // });
  // enquire.register('screen and (max-width: 60em)', {
  //   match: function() {
  //     $main_navigation.hide();
  //   }
  // });

  $("#show_mobile_nav").on('click', function() {
    return $main_navigation.slideToggle();
  });
  $('#slides').makeSlideshow($("#slides_control"), $('.call_to_action_content h3'), $('.intro .popup .content'), $('.call_to_action_content a'));
  $('#news-slides').makeSlideshow(null, $('.intro_block .intro_content h1'));
  $('.project').each(function(i, e) {
    $(e).find('ul.thumbnails li a').click(function(event) {
      event.preventDefault();
      $(e).find('.image img').remove();
      $(e).find('.image').css('background-image', "url('" + ($(this).attr('href')) + "')");
      $(e).find('ul.thumbnails li').removeClass('active');
      return $(this).parent().addClass('active');
    }).first().trigger('click');
    if ($(e).find('ul.thumbnails li').length === 1) {
      $(e).find('ul.thumbnails').hide();
      return $(e).addClass('one-image');
    }
  });
  $('.intro_block .call_to_action a').first().on('click', function(event) {
    event.preventDefault();
    return $('.intro').addClass('show_popup');
  });
  $('.intro .popup a.close').on('click', function(event) {
    event.preventDefault();
    return $('.intro').removeClass('show_popup');
  });
  $('.intro').addClass('has-3d');
  (function() {
    var $map, country, country_list, i, j, len, results, transition;
    $map = $('#project_map svg');
    transition = 1000;
    country_list = ['#CA', '#BR', '#GB', '#AU', '#CN', '#IE', '#ES', '#US', '#MA', '#EG', '#OM', '#MX', '#CL', '#NZ', '#ID'];
    results = [];
    for (i = j = 0, len = country_list.length; j < len; i = ++j) {
      country = country_list[i];
      results.push((function(country_selector, i) {
        return setTimeout(function() {
          return $(country_selector, $map).css({
            fill: '#ec3a85'
          });
        }, transition * i);
      })(country, i));
    }
    return results;
  })();
  $("a[data-video-src]").on("click", function(event) {
    var $el;
    if ($(window).width() > 640) {
      event.preventDefault();
      $el = $("<div class=\"video-popup\"><iframe src=\"" + ($(this).data('video-src')) + "\" width=\"630\" height=\"354\" frameborder=\"0\" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>");
      return $el.lightbox_me({
        destroyOnClose: true,
        centered: true
      });
    }
  });
});
